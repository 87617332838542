.file-upload {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;

    &-btn {
        padding: 11px 20px;
        background-color: darken($purple, 10);
        border-radius: 7px 0 0 7px;
        border: none;
        font-size: 16px;
        color: $white;
        text-transform: uppercase;
        transition: all 0.1s ease-in;
        cursor: pointer;

        &:hover {
            background-color: darken($purple, 20);
        }
    }

    &-input {
        display: none;
    }

    .file-name {
        flex: 1;
        padding: 11px 20px;
        background-color: $purple;
        border-radius: 0 7px 7px 0;
        font-size: 16px;
        color: $white;
    }
}
