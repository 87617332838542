* {
    font-family: 'proxima-soft';

    &:focus {
        outline: none;
    }
}

@import './assets/styles/colors';

@import './components/Components';
@import './templates/headers/Headers';
@import './templates/userAgent/UserAgent';
@import './templates/fileUpload/FileUpload';
@import './templates/fileDownload/FileDownload';
@import './templates/headers/Headers';
@import './templates/userAgent/UserAgent';
