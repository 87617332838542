.file-download {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;

    .file-download-btn {
        padding: 11px 20px;
        background-color: darken($purple, 10);
        border-radius: 7px;
        border: none;
        font-size: 16px;
        color: $white;
        text-transform: uppercase;
        width: 125px;
        transition: all 0.1s ease-in;
        cursor: pointer;

        &:disabled {
            background-color: $bright-grey !important;
            color: $grey-blue !important;
        }

        &:hover {
            background-color: darken($purple, 20);
        }
    }

    p {
        margin-top: 0;
        padding: 11px 20px;

        &.error {
            padding: 0 20px !important;
            font-weight: 700;
            color: $warm-pink;
        }
    }
}
