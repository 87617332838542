.refresh-button {
    position: absolute;
    margin: 16px 16px 0;
    top: 0;
    right: 0;
    width: 28px;
    height: 28px;
    background: url('../../../src/assets/icons/refreshButton.svg') no-repeat
            center,
        $bright-grey;
    border-radius: 6px;
    border: none;
    transition: all 0.1s ease-in;
    cursor: pointer;

    &:hover {
        background-color: darken($bright-grey, 5);
    }
}
