.headers-table {
    width: 100%;
    border-spacing: 0;
    text-align: left;

    .head {
        font-weight: 700;

        .header {
            border-radius: 7px 0 0 0;
        }

        .value {
            border-radius: 0 7px 0 0;
        }

        th {
            padding: 5px 10px;
            background-color: $bright-grey;
        }
    }

    tr {
        td {
            padding: 5px 10px;
            border-bottom: 1px solid $bright-grey;
        }

        td:nth-of-type(1) {
            width: 25%;
        }

        &:hover {
            background-color: darken($white, 5);
        }
    }
}
