.card {
    position: relative;
    margin: 0 12px 24px;
    padding: 16px;
    border: 1px solid $pale-grey;
    border-radius: 6px;
    box-shadow: 0 7px 5px -5px $box-shadow-w-opacity-2;

    .card-header {
        margin: 0 0 8px;
    }
}
