$white: #ffffff;
$black: #000000;

$bright-grey: #e3e2e9;
$pale-grey: #eff0f6;
$grey-blue: #6a6d8a;
$purple: #6c30cf;
$warm-pink: #ff516a;

$box-shadow-w-opacity-2: rgba(106, 109, 138, 0.2);
